import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectLiveTradeSymbols } from "./reducers/terminalReducer";
import TradeContainer from "./TradeContainer";
import { Box, Typography } from "@mui/material";

interface ComponentProps {
    isTinyScreen: boolean;
  }
  

function Trades(props: ComponentProps) {
  const liveTradeSymbols = useSelector(selectLiveTradeSymbols, shallowEqual);
  
  // console.log('did liveTradeSymbols change?', liveTradeSymbols);

  return (
    <>
      {
        [...liveTradeSymbols].reverse().map((t) => {
            return <TradeContainer symbol={t} key={t} isTinyScreen={props.isTinyScreen} />;
        })
      }

      {
        liveTradeSymbols.length === 0 
        ? <Box sx={{p:2 }}>
            <Typography>No live or closed trades</Typography>
        </Box>
        : null
      }
    </>
  );
}

export default React.memo(Trades);
