import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Hidden, IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAvailableQuotes, selectExchanges, selectExchangeMarkets } from "../../reducers/priceReducer";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { castStringToEnum, getExchangeUri } from "../../utils/LinkUtils";
import { blue } from "@mui/material/colors";
import { MarketType } from "../../enums/MarketType";
import { Exchange } from "../../enums/Exchange";
import { StandardiseCoinName } from "../../utils/PriceUtils";

interface ComponentProps {
  symbol: string;
}

function SymbolPriceAvailability(props: ComponentProps) {

  const availableQuotes = useSelector(selectAvailableQuotes);
  const exchanges = useSelector(selectExchanges);
  const exchangeMarkets = useSelector(selectExchangeMarkets);

  const [showAvailability, setShowAvailability] = React.useState<boolean>(false);

  return (
    <>

<Dialog
        open={showAvailability}
        onClose={() => { setShowAvailability(false) }}
        >
        <DialogTitle>{props.symbol} availability</DialogTitle>
        <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Exchange</TableCell>
                        <TableCell>Market</TableCell>
                        <TableCell>Quotes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        exchangeMarkets.map(e => {
                            return (<TableRow key={`${e.exchange}|${e.market}`}>
                            <TableCell>{e.exchange}</TableCell>
                            <TableCell>{e.market}</TableCell>
                            <TableCell>
                                {
                                    availableQuotes
                                        .filter(q => StandardiseCoinName(q.base, q.exchange, q.market) === props.symbol && q.exchange.toLowerCase() === e.exchange.toLowerCase() && (q.market === e.market || q.market === ""))
                                        .map(q => {
                                            let sortedQuotes = [...q.quotes].map(String).sort((a, b) => { return a.localeCompare(b); });
                                            return sortedQuotes.flatMap(qq => {
                                                    let exchange = castStringToEnum(e.exchange, Exchange);
                                                    return exchange && getExchangeUri(exchange, e.market === "Spot" ? MarketType.Spot : MarketType.Futures, q.exchangeBase || props.symbol, qq)
                                                    ? [<Link color={blue[400]} sx={{textDecoration: 'none'}} href={getExchangeUri(exchange, e.market === "Spot" ? MarketType.Spot : MarketType.Futures, q.exchangeBase || props.symbol, qq)} target="_blank">{qq}</Link>, " "]
                                                    : qq + " ";
                                            });
                                        })
                                }
                            </TableCell>
                        </TableRow>)
                        })
                    }
                </TableBody>
            </Table>
        
        </DialogContent>
    </Dialog>
    <Box>
    <Hidden smDown>
    <Tooltip title="Availability on exchange(s)"> 
        <IconButton 
            onClick={() => { setShowAvailability(true) }}
            sx={{ml: 1, mr: 0}}
            >
            <PriceCheckIcon />
        </IconButton>
    </Tooltip>
    </Hidden>
    </Box>
    </>
  );
}

export default SymbolPriceAvailability;
