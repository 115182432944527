import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { UIOrder } from "../types/BotOrder";
import { TerminalUIUpdate } from "../types/BotUpdate";
import { setAccessToken } from "./authorizationSlice";
import { BotTrade } from "../types/BotTrade";

export interface BotConnection {
  targetIp: string;
  authCode: string;
  disabled: boolean;
}

export interface BotUpdateTime {
  botName: string;
  ip: string;
  updateTimestamp: string;
}

export interface TradeSettingUpdate {
  tradeId: string;
  value: number;
}

/** A more generic trade settings update, e.g. for increase/decrease position size */
export interface TradeSettingUpdateString {
  tradeId: string;
  value: string;
}

// Define a type for the slice state
interface BotState {
  bots: BotConnection[];
  requestNewConnection: boolean;
  isConnected: boolean;
  lastWebsocketReceived: number;
  status: TerminalUIUpdate[];
  lastUpdated: BotUpdateTime[];
  liveTradeSymbols: string[];
  terminalTab: number;
  newsRatio: number;
  chartsPerRow: number;
  maxCoins: number;
  [key: string]: BotTrade[] | TerminalUIUpdate[] | BotConnection[] | boolean | number | string[] | BotUpdateTime[]; // Only for TerminalUIUpdate[] but all need to be possible for this to work
}

// Define the initial state using that type
const initialState: BotState = {
  bots: [],
  requestNewConnection: true,
  isConnected: false,
  lastWebsocketReceived: 0,
  status: [],
  lastUpdated: [],
  liveTradeSymbols: [],
  terminalTab: 0,
  newsRatio: 6,
  chartsPerRow: 6,
  maxCoins: 6,
};

export const terminalSlice = createSlice({
  name: "terminal",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    requestConnect: (state) => {
      state.requestNewConnection = true;
    },
    disconnect: (state) => {
      state.isConnected = false;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.requestNewConnection = false;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    startConnecting: (state, action: PayloadAction<BotConnection>) => {
      console.log(action);
      state.bots = [
        ...state.bots.filter(
          (b) =>
            b.targetIp !== action.payload.targetIp ||
            b.authCode !== action.payload.authCode
        ),
        action.payload,
      ].filter((b) => b.targetIp !== "");
      //state.value += action.payload
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    startPolling: (state, action: PayloadAction<BotConnection>) => {
        console.log(action);
        state.bots = [
          ...state.bots.filter(
            (b) =>
              b.targetIp !== action.payload.targetIp ||
              b.authCode !== action.payload.authCode
          ),
          action.payload,
        ].filter((b) => b.targetIp !== "");
        //state.value += action.payload
      },
    subscribeToBot: (state, action: PayloadAction<BotConnection>) => {
      console.log(action);
    },
    updateBotStatus: (state, action: PayloadAction<TerminalUIUpdate>) => {
      state.lastWebsocketReceived = new Date().valueOf();

      if (action.payload.timeOnly) {
        state.lastUpdated = [
          ...state.lastUpdated.filter(
            (s) => s.botName !== action.payload.botName
          ),
          {
            botName: action.payload.botName,
            ip: action.payload.fromIp,
            updateTimestamp: action.payload.updateTimestamp,
          },
        ];

        // If this bot hasn't posted a full status update, log it here
        //let botStatusExists = state.status.findIndex(s => s.botName === action.payload.botName);
        //if (botStatusExists === -1) {
        //state.status = [...state.status, action.payload].sort((a, b) => a.botName.localeCompare(b.botName));
        //}
      } else {
        let tempStatus = [
          ...state.status.filter((s) => s.botName !== action.payload.botName),
          (action.payload.tradeSettings || []).length > 0 ? 
            action.payload
            : { ...action.payload, tradeSettings: state.status.find((s) => s.botName === action.payload.botName)?.tradeSettings }
        ].sort((a, b) => a.botName.localeCompare(b.botName));
        
        state.status = tempStatus;

        state.lastUpdated = [
          ...state.lastUpdated.filter(
            (s) => s.botName !== action.payload.botName
          ),
          {
            botName: action.payload.botName,
            ip: action.payload.fromIp,
            updateTimestamp: action.payload.updateTimestamp,
          },
        ];

        // Create a shortcut list of live traded symbols
        state.liveTradeSymbols = tempStatus
          .flatMap((s) => s.trades.map((t) => t.baseCurrency
            .replace("10000000", "")
            .replace("1000000", "")
            .replace("100000", "")
            .replace("10000", "")
            .replace("1000", "")
            .replace("100", "")
            .replace("10", "")
            .replace("2L", "")
            .replace("3L", "")
            .replace("4L", "")
            .replace("5L", "")
            .replace("2S", "")
            .replace("3S", "")
            .replace("4S", "")
            .replace("5S", "")
            ))
          .filter((v, i, a) => a.indexOf(v) === i);

        // Iterate each symbol and add to a dynamic key property and add the statuses for that symbol
        state.liveTradeSymbols.forEach(symbol => {
            state[symbol] = tempStatus
                .flatMap(s => s.trades)
                .filter(s => s.baseCurrency
                        .replace("10000000", "")
                        .replace("1000000", "")
                        .replace("100000", "")
                        .replace("10000", "")
                        .replace("1000", "")
                        .replace("100", "")
                        .replace("10", "")
                        .replace("2L", "")
                        .replace("3L", "")
                        .replace("4L", "")
                        .replace("5L", "")
                        .replace("2S", "")
                        .replace("3S", "")
                        .replace("4S", "")
                        .replace("5S", "")
                     === symbol);
        });
        //console.log('state.liveTradeSymbols', state.liveTradeSymbols);
      }

      /*
      // Only if more than the update timestamp has changed, update the state
      if (JSON.stringify(tempStatus.map(s => ({...s, updateTimestamp: 0}))) !== JSON.stringify(state.status.map(s => ({...s, updateTimestamp: 0})))) {
          state.status = tempStatus; 

          // Store a list of symbols that have an active trade record
          let tempLiveTradeSymbols = tempStatus.flatMap(s => s.trades.map(t => t.baseCurrency)).filter((v, i, a) => a.indexOf(v) === i);
          if (JSON.stringify(tempLiveTradeSymbols) !== JSON.stringify(state.liveTradeSymbols)) {
              state.liveTradeSymbols = tempLiveTradeSymbols;
          }

      }
      */
    },
    updateTerminalTab: (state, action: PayloadAction<number>) => {
      state.terminalTab = action.payload;
    },

    setTelegram: (state, action: PayloadAction<number>) => {
        state.telegram = Math.max(0, action.payload);
      },

    setNewsRatio: (state, action: PayloadAction<number>) => {
      state.newsRatio = Math.max(0, Math.min(action.payload, 12));
    },

    setChartsPerRow: (state, action: PayloadAction<number>) => {
      state.chartsPerRow = Math.max(1, Math.min(action.payload, 12));
    },

    setMaxCoins: (state, action: PayloadAction<number>) => {
      state.maxCoins = Math.max(1, Math.max(action.payload, 3));
    },

    // All handled in the middleware layer
    sendTrade: (state, action: PayloadAction<UIOrder>) => {},
    forceSell: (state, action: PayloadAction<string>) => {},
    clearTrade: (state, action: PayloadAction<string>) => {},
    extendTimer: (state, action: PayloadAction<TradeSettingUpdate>) => {},
    changeTakeProfit: (state, action: PayloadAction<TradeSettingUpdate>) => {},
    setIncrementalTakeProfit: (state, action: PayloadAction<TradeSettingUpdate>) => {},
    cancelTakeProfit: (state, action: PayloadAction<string>) => {},
    triggerAutoReducingTakeProfit: (state, action: PayloadAction<string>) => {},
    cancelAutoReducingTakeProfit: (state, action: PayloadAction<string>) => {},
    setTakeProfit: (state, action: PayloadAction<TradeSettingUpdate>) => {},
    setStopLoss: (state, action: PayloadAction<TradeSettingUpdate>) => {},
    setTrailingStopLoss: (state, action: PayloadAction<TradeSettingUpdate>) => {},
    increasePosition: (state, action: PayloadAction<TradeSettingUpdateString>) => {},
    getPersonalisationSettings: (state) => {},
    getPersonalisationSettingsAlternate: (state) => {},
    storePersonalisationSettings: (state) => {},
  },
});

export const {
  requestConnect,
  startConnecting,
  startPolling,
  subscribeToBot,
  connectionEstablished,
  disconnect,
  updateBotStatus,
  sendTrade,
  forceSell,
  extendTimer,
  clearTrade,
  changeTakeProfit,
  setIncrementalTakeProfit,
  cancelTakeProfit,
  triggerAutoReducingTakeProfit,
  cancelAutoReducingTakeProfit,
  setTakeProfit,
  setStopLoss,
  setTrailingStopLoss,
  increasePosition,
  updateTerminalTab,
  getPersonalisationSettings,
  getPersonalisationSettingsAlternate,
  storePersonalisationSettings,
  setNewsRatio,
  setChartsPerRow,
  setMaxCoins,
  setTelegram,
} = terminalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const isConnected = (state: RootState) => state.terminal.isConnected;
export const requestNewConnection = (state: RootState) => state.terminal.requestNewConnection;
export const status = (state: RootState) => state.terminal.status;
export const lastWebsocketReceived = (state: RootState) => state.terminal.lastWebsocketReceived;
export const lastUpdated = (state: RootState) => state.terminal.lastUpdated;
export const connectedCount = (state: RootState) => state.terminal.lastUpdated?.length;
export const selectLiveTradeSymbols = (state: RootState) =>  state.terminal.liveTradeSymbols;
export const selectBots = (state: RootState) => state.terminal.bots;
export const selectTelegramId = (state: RootState) => state.terminal.telegram;

// Get all trades for a specific symbol
export const selectTrades = (state: RootState, key: string) => {
    return state.terminal[key] as BotTrade[];
};

// Main screen tabs
export const selectTerminalTab = (state: RootState) => state.terminal.terminalTab;
export const selectNewsRatio = (state: RootState) => state.terminal.newsRatio;
export const selectChartsPerRow = (state: RootState) => state.terminal.chartsPerRow;
export const selectMaxCoins = (state: RootState) => state.terminal.maxCoins;

export default terminalSlice.reducer;
