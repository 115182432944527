import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { isConnected, connectedCount, requestConnect, disconnect, BotConnection, startConnecting, getPersonalisationSettings, getPersonalisationSettingsAlternate } from "./reducers/terminalReducer";
import { Badge, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { selectAccessToken, selectBasicLogin, selectIsLoggedIn } from "./reducers/authorizationSlice";
import { Authorization } from "./auth/Authorization";
import { LoadingButton } from "@mui/lab";
import WifiIcon from "@mui/icons-material/Wifi";
import { requestConnect as requestNewConnectionPrices  } from "./reducers/priceReducer";
import { requestConnect as requestNewConnectionNews  } from "./reducers/newsReducer";

export default function ConnectionStatusMobile() {
  
  const connected = useSelector(isConnected, shallowEqual);
  const botCount = useSelector(connectedCount, shallowEqual);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const accessToken = useSelector(selectAccessToken); 
  const basicLogin = useSelector(selectBasicLogin); 

  const [anchorEl, setAnchorEl] = React.useState<Element|null>(null);

  const [open, setOpen] = React.useState<boolean>(!isLoggedIn);
  const [connecting, setConnecting] = React.useState(false);

  //const showNewConnection = useSelector(requestNewConnection);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (connecting) {
      window.setTimeout(() => {
        setOpen(false);
      }, 1500);
    }
  }, [connecting]);

  const [botConnection, setBotConnection] = React.useState<BotConnection>({
    targetIp: "",
    authCode: "",
    disabled: true,
  });

  const isBotConnected = useSelector(isConnected);

  const connect = () => {
    setConnecting(true);

    console.log('Connected to bot', botConnection)

    dispatch(startConnecting(botConnection));

    // If this is the first bot to connect, set up the news & price connections now
    if (isBotConnected === false) {
        //dispatch(requestNewConnectionPrices());
        //dispatch(requestNewConnectionNews());
    }
  }

  React.useEffect(() => {
    setConnecting(true);
  }, []);
  

  React.useEffect(() => {
    dispatch(getPersonalisationSettings());
  }, [accessToken]);

  React.useEffect(() => {
    // Can be a different function in the future if needed, but for now the same logic will handle Discord Auth tokens & direct username/password/code
    dispatch(getPersonalisationSettings());
  }, [basicLogin]);

  React.useEffect(() => {
    setConnecting(false);
    setBotConnection({
        targetIp: "",
        authCode: "",
        disabled: true,
        });
  }, [open, botCount])
/*
  React.useEffect(() => {
    if (isLoggedIn && botCount === 0) {
        connect();
    }
  }, [isLoggedIn])
  */
  // TODO: Read terminal WS state

  return (
    <React.Fragment>
      
{botCount === 0 || open && !isLoggedIn
?

      <Dialog
        maxWidth="md"
        sx={{ p: 0 }}
        open={!isLoggedIn || open}
        onClose={() => {
          setOpen(false);
        }}
        BackdropProps={{ style: { backgroundColor: 'black' } }}
      >

{
    !isLoggedIn
    ? <Authorization />
    : null
    }
      </Dialog>
: null}

    </React.Fragment>
  );
}