import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppDispatch } from '../store';
import {
  setLoggedIn,
  setAccessToken,
  setTokenExpiryDate,
  selectIsLoggedIn,
  selectTokenExpiryDate,
  selectAccessToken,
  setBasicLogin
} from '../reducers/authorizationSlice';
//import { setUserProfileAsync } from '../spotifyExample/spotifyExampleSlice';
import { getAuthorizeHref } from '../oauthConfig';
import { getHashParams, removeHashParamsFromUrl } from '../utils/hashUtils';
import { Box, Button, Checkbox, Divider, IconButton, Paper, SvgIcon, TextField, Typography } from '@mui/material';
import { setUserProfileAsync } from '../reducers/discordReducer';
import { Check, CheckCircleOutlineOutlined, CloseOutlined, VpnKey } from '@mui/icons-material';

const hashParams = getHashParams();
const access_token = hashParams.access_token;
const expires_in = hashParams.expires_in;
removeHashParamsFromUrl();

const DiscordIcon = () => {
    return (<SvgIcon viewBox="0 0 127.14 96.36">
                <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
            </SvgIcon>
        );
}

const setCookie = (name: string, value: string, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Set expiration time
    const expires = `expires=${date.toUTCString()}`;
  
    // Set cookie with attributes
    document.cookie = `kt-${name}=${encodeURIComponent(value)}; ${expires}; path=/; Secure; SameSite=Strict`;
  };

  const getCookie = (name: string) => {

    // Check the querystring first
    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get(name);
    if (queryParam) {
      return queryParam;
    }

    const cookies = document.cookie.split('; ');
    const cookie = cookies.find((row) => row.startsWith(`kt-${name}=`));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
  };

  const hasQueryParams = () => {
    return window.location.search.length > 0;
  }

export function Authorization() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const tokenExpiryDate = useSelector(selectTokenExpiryDate);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useAppDispatch();

  const [showBasicAuth, setShowBasicAuth] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [code, setCode] = React.useState('');
  const [showNews, setShowNews] = React.useState(false);
  const [showPrices, setShowPrices] = React.useState(false);

  // Update username, password and code in cookies
    useEffect(() => {
        if (username && password && code) {
            setCookie('username', username, 365);
            setCookie('password', password, 365);
            setCookie('code', code, 365);
        }

        if (hasQueryParams() && username && password && code) {
            processLogin();
        }
    }
    , [username, password, code]);

    // Get username, password and code from cookies
    useEffect(() => {
        const cookieUsername = getCookie('username');
        const cookiePassword = getCookie('password');
        const cookieCode = getCookie('code');

        // Log what we found from cookies
        console.log('Found username', cookieUsername);
        console.log('Found password', cookiePassword);
        console.log('Found code', cookieCode);

        if (cookieUsername && cookiePassword && cookieCode) {
            setUsername(cookieUsername);
            setPassword(cookiePassword);
            setCode(cookieCode);
        }
    }, []);

  useEffect(() => {
    if (access_token) {
      dispatch(setLoggedIn(true));
      dispatch(setAccessToken(access_token));
      dispatch(setTokenExpiryDate(Number(expires_in)));
      dispatch(setUserProfileAsync(access_token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const processLogin = () => {

    console.log('Logging in with', username, password, code);
    
    if (username && password && code) {

        let userLogin = {
            username: username,
            password: password,
            code: code,
            showNews: showNews,
            showPrices: showPrices,
        }

        // Store user login details in a cookie (long-lived)


      dispatch(setBasicLogin(userLogin));
      dispatch(setLoggedIn(true));
    }
  };

  // Get the user details
  if (accessToken) {
    fetch("https://discord.com/api/users/@me", { headers: { 'Authorization': 'Bearer ' + accessToken }})
    .then(resp => resp.json())
    .then(resp => console.log(resp));
  }
  
  return (
    <div>
      <div>
        {!isLoggedIn &&
            <Paper elevation={3} sx={{p: 2, maxWidth: 300}}>
                <Typography variant="body1">Sign in</Typography>

                {!showBasicAuth
                ?
                    <>
                        <Button
                            sx={{pl:2, mt: 3, mb: 0, width: '100%'}}
                            onClick={() => setShowBasicAuth(true)}
                            startIcon={<VpnKey />}
                            variant="outlined"
                        >
                            Basic auth
                        </Button>
                        <Button
                            sx={{pl:2, mt: 3, mb: 3, width: '100%'}}
                            onClick={() => window.open(getAuthorizeHref(), '_self')}
                            startIcon={<DiscordIcon />}
                            variant="outlined"
                        >
                            Discord auth
                        </Button>
                        <Divider />
                        <Typography sx={{mt: 3, mb: 1}} variant="body2">This will only provide your Discord username and a token, no email or other details are provided</Typography>
                    </>
                : 
                    <>
                        
                        <TextField sx={{mt: 2, mb: 1, width: '100%'}} label="Discord ID" name="kt-username" id="kt-username" onBlur={(e) => setUsername(e.currentTarget.value)} defaultValue={username} />
                        <TextField sx={{mt: 1, mb: 1, width: '100%'}} label="Password" name="kt-password" id="kt-password" type='password' onBlur={(e) => setPassword(e.currentTarget.value)} defaultValue={password} />
                        <TextField sx={{mt: 1, mb: 1, width: '100%'}} label="Code" name="kt-code" id="kt-code" onBlur={(e) => setCode(e.currentTarget.value)} defaultValue={code} />
                        <Checkbox checked={showNews} onChange={(e) => setShowNews(e.currentTarget.checked)} /> Show news
                        <Checkbox checked={showPrices} onChange={(e) => setShowPrices(e.currentTarget.checked)} /> Show prices
                        <Box
                            sx={{mt: 1, mb: 0, display: 'flex', justifyContent: 'space-between'}}
                        >
                        <IconButton onClick={() => setShowBasicAuth(false)}><CloseOutlined /></IconButton>
                        <IconButton onClick={processLogin} disabled={!username || !password || !code}><CheckCircleOutlineOutlined /></IconButton>
                        </Box>
                        
                    </>
                }
            </Paper>
          }
        {isLoggedIn && <div>Token expiry date: {tokenExpiryDate}</div>}
        {isLoggedIn && <div>Access token: {accessToken}</div>}
      </div>
    </div>
  );
}