import React from "react";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Hidden,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  useTheme,
  Collapse,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { grey, green, red, blue, orange, amber } from "@mui/material/colors";
import SplitButton from "../SplitButton";
import ConfigOption from "../../types/ConfigOption";
import { useSearchParams } from "react-router-dom";
//import {
//  WebSocketContext,
//  WebsSocketContextInterface,
//} from "../../middleware/Websocket";
import TradeSettings from "../../types/TradeSettings";
import { UIOrder, BotTarget } from "../../types/BotOrder";
import TradePropertySelector from "./TradePropertySelector";
import SymbolPrice from "./SymbolPrice";
import SymbolPriceHeading from "./SymbolPriceHeading";
//import { SymbolConfig, SymbolWatcherContext, SymbolWatcherContextInterface } from "../../contexts/SymbolWatcher";
import { selectBig, selectScalp, selectSmall, selectTest, SymbolConfig, TradeOption } from "../../reducers/symbolReducer";
import CloseIcon from "@mui/icons-material/Close";
import { DateTime } from "luxon";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useHotkeys } from "react-hotkeys-hook";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
//import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  removeSymbol,
  updateSymbol,
  togglePinning,
  toggleFullscreen,
} from "../../reducers/symbolReducer";
import {
  sendTrade,
  connectedCount,
  clearTrade,
  selectLiveTradeSymbols,
  selectBots,
  lastUpdated,
} from "../../reducers/terminalReducer";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PushPinIcon from "@mui/icons-material/PushPin";
import LockIcon from "@mui/icons-material/Lock";
import SymbolTrades from "./SymbolTrades";
import {
  selectInitPercentOptions,
  selectLeverageOptions,
  selectStopLossPercentOptions,
  selectTakeProfitPercentOptions,
  selectTimerOptions,
  TradeValue,
} from "../../reducers/symbolReducer";
import SymbolPriceTradingViewChart from "./SymbolPriceTradingViewChart";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NewsTable from "../NewsTable";
import SymbolPriceAvailability from "./SymbolPriceAvailability";
import SymbolMarketCap from "./SymbolMarketCap";

interface ComponentProps {
  symbol: SymbolConfig;
  index: number; // Used for hotkeys
  drawerWidth: number;
  isSmallScreen: boolean;
}

interface OrderDetails {
  index: number;
  side: "Long" | "Short";
  tradeOption?: TradeOption;
}

const usdtLocaleProfit = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const percentFormat = Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

function SymbolContainer(props: ComponentProps) {
  let theme = useTheme();

  const dispatch = useDispatch();

  const initPercentOptions = useSelector(selectInitPercentOptions);
  const stopLossPercentOptions = useSelector(selectStopLossPercentOptions);
  const takeProfitPercentOptions = useSelector(selectTakeProfitPercentOptions);
  const leverageOptions = useSelector(selectLeverageOptions);
  const timerOptions = useSelector(selectTimerOptions);
  const liveTradeSymbols = useSelector(selectLiveTradeSymbols);
  const bots = useSelector(lastUpdated);

    const test = useSelector(selectTest);
    const scalp = useSelector(selectScalp);
    const small = useSelector(selectSmall);
    const big = useSelector(selectBig);

    const tradeButtons = [test, scalp, small, big];

  const botsConnected = useSelector(connectedCount, shallowEqual);

  // ORDER CONFIRMATION //
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState<OrderDetails | null>(
    null
  );

  const tradingDisabled = false;

  const requestOrder = (side: "Long" | "Short", tradeOption: TradeOption) => {
    // Need symbol in here
    if (!tradingDisabled) {
      setOrderDetails({ index: 0, side: side, tradeOption: tradeOption });
    }
  };

  React.useEffect(() => {
    if (orderDetails && !showConfirmation) {
      setShowConfirmation(true);
    } else {
      if (!orderDetails && showConfirmation) {
        setShowConfirmation(false);
      }
    }
  }, [orderDetails]);

  const rejectOrder = () => {
    setOrderDetails(null);
  };

  const submitOrder = () => {
    if (orderDetails !== null) {
      let orderToPlace: UIOrder = {
        currency: props.symbol.symbol,
        direction: orderDetails.side === "Short" ? "short" : "long",
        initialRisk: orderDetails.tradeOption?.initPercent || props.symbol.initialRisk,
        takeProfit: orderDetails.tradeOption?.takeProfit || props.symbol.takeProfit,
        takeProfitVariable: orderDetails.tradeOption?.takeProfit || props.symbol.takeProfit,
        incrementalTakeProfit: 1.0,
        stopLoss: orderDetails.tradeOption?.stopLoss || props.symbol.stopLoss,
        trailingStopLoss: orderDetails.tradeOption?.trailingStopLoss || props.symbol.trailingStopLoss,
        timedSell: orderDetails.tradeOption?.timedSell || props.symbol.timedSell,
        investmentMaxRisk: orderDetails.tradeOption?.value || 0,
        investmentMaxAmount: null,
        leverage: orderDetails.tradeOption?.leverage || props.symbol.leverage,
        label: `Terminal${orderDetails.tradeOption?.label ? `|${orderDetails.tradeOption?.label.trim()}` : ''}`,
        maxPumpWindowSeconds: (orderDetails.tradeOption?.timedSell || props.symbol.timedSell) / 1000,
        takeProfitReductionPercent: 0.25,
        takeProfitReductionPeriodSeconds: 10,
        targetBots: selectedBots.filter(b => b.checked),
      };

      // Send it
      dispatch(sendTrade(orderToPlace));
    }

    setOrderDetails(null);
  };

  const [showTradeStrategy, setShowTradeStrategy] = React.useState<boolean>(
    !props.isSmallScreen
  );

  const [date, setDate] = React.useState<number>(new Date().valueOf());

  React.useEffect(() => {
    let interval = setInterval(() => {

        if (props.symbol.addedTimestamp && (date - props.symbol.addedTimestamp) <= 10000) {
            setDate(new Date().valueOf());
        }

    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

    const [showExchangeData, setShowExchangeData] = React.useState(false);
    const [exchangeData, setExchangeData] = React.useState<string>("");

    const [isExchangeDataLoading, setIsExchangeDataLoading] = React.useState(false);

    const loadExchangeData = () => {

        setIsExchangeDataLoading(true);

        // Fetch the data
        fetch(`https://news.kryptrader.com/trades?coin=${props.symbol.symbol}`, {
            method: "GET",
          })
        .then(response => response.text())
        .then(data => {

            // Replace all newlines with <br />
            data = data.replace(/\r\n/g, "<br />");

            // Set the data
            setExchangeData(data);
            
            //console.log('data', data);
        })
        .finally(() => {
            setIsExchangeDataLoading(false);
        });
    }

  const [selectedBots, setSelectedBots] = React.useState<BotTarget[]>(bots.filter(b => b.ip).sort((a, b) => {
    // First, try sorting by IP
    const ipCompare = a.ip.localeCompare(b.ip);
    if (ipCompare !== 0) return ipCompare; // If IPs are different, sort by IP
    
    // If IPs are the same, sort by botName
    return a.botName.localeCompare(b.botName);
  }).map(b => ({ botName: b.botName, ip: b.ip, checked: true })));

  
//useHotkeys(test.shortcutKeys[0], () => { if (props.index === 0) { if (test.visible) { requestOrder("Long", test)}} }, [props.index]);
//useHotkeys(test.shortcutKeys[1], () => { if (props.index === 0) { if (test.visible) { requestOrder("Short", test)}} }, [props.index]);

//useHotkeys(scalp.shortcutKeys[0], () => { if (props.index === 0) { if (scalp.visible) { requestOrder("Long", scalp)}} }, [props.index]);
//useHotkeys(scalp.shortcutKeys[1], () => { if (props.index === 0) { if (scalp.visible) { requestOrder("Short", scalp)}} }, [props.index]);

//useHotkeys(small.shortcutKeys[0], () => { if (props.index === 0) { if (small.visible) { requestOrder("Long", small)}} }, [props.index]);
//useHotkeys(small.shortcutKeys[1], () => { if (props.index === 0) { if (small.visible) { requestOrder("Short", small)}} }, [props.index]);

//useHotkeys(big.shortcutKeys[0], () => { if (props.index === 0) { if (big.visible) { requestOrder("Long", big)}} }, [props.index]);
//useHotkeys(big.shortcutKeys[1], () => { if (props.index === 0) { if (big.visible) { requestOrder("Short", big)}} }, [props.index]);

  return (
    <>
      <Dialog
        maxWidth="md"
        sx={{ p: 2 }}
        open={showExchangeData}
        onClose={() => {
            setShowExchangeData(false);
        }}
        >
        <DialogTitle>Exchange trade data</DialogTitle>
        <DialogContent>
            {isExchangeDataLoading ? "Loading ..." /* <CircularProgress /> */ : null}
            {exchangeData ? <div dangerouslySetInnerHTML={{ __html: exchangeData }} /> : null}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant="outlined"
            sx={{ mb: 0 }}
            onClick={() => setShowExchangeData(false)}
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                setShowExchangeData(false);
              }
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            sx={{
              mb: 0,
            }}
            onClick={loadExchangeData}
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                loadExchangeData();
              }
            }}
            autoFocus
          >
            Load
          </Button>
        </DialogActions>
        </Dialog>
      <Dialog
        maxWidth="md"
        sx={{ p: 2 }}
        open={showConfirmation}
        onClose={() => {
          rejectOrder();
        }}
      >
        <DialogTitle>Whoa, slow down cowboy</DialogTitle>
        <DialogContent>
          {botsConnected === 0 ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              No trading bots connected
            </Alert>
          ) : null}
          <Typography color="text.secondary" component="span">
            You are about to{" "}
          </Typography>
          <Typography color={orderDetails?.side === "Short" ? red[500] : green[500]} component="span" sx={{fontWeight: 500}}>{orderDetails?.side.toUpperCase()}</Typography>{" "}
          <Typography component="span" sx={{fontWeight: 500}}>
            {(orderDetails?.tradeOption?.value || 0) === 0 ? "EVERYTHING" : (orderDetails?.tradeOption?.value || 0) > 1
              ? usdtLocaleProfit.format(orderDetails?.tradeOption?.value || 0)
              : (orderDetails?.tradeOption?.value || 0).toLocaleString(undefined, {
                  style: "percent",
                  minimumFractionDigits: 0,
                })}
          </Typography>{" "}
          <Typography color="text.secondary" component="span">
            on{" "}
          </Typography>
          <Typography color={orderDetails?.side === "Short" ? red[500] : green[500]} component="span" sx={{fontWeight: 500}}>{props.symbol.symbol}</Typography>
          <TableContainer>
            <Table size="small" sx={{ mt: 4 }}>
              <TableBody>
                <TableRow>
                <TableCell colSpan={2}><strong>Settings</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Initial risk</TableCell>
                  <TableCell>
                    {(orderDetails?.tradeOption?.initPercent || props.symbol.initialRisk).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Take profit</TableCell>
                  <TableCell>
                    {(orderDetails?.tradeOption?.takeProfit || props.symbol.takeProfit).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Stop loss</TableCell>
                  <TableCell>
                    {(orderDetails?.tradeOption?.stopLoss || props.symbol.stopLoss).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Trailing stop loss</TableCell>
                  <TableCell>
                    {(orderDetails?.tradeOption?.trailingStopLoss || props.symbol.trailingStopLoss).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max leverage</TableCell>
                  <TableCell>{(orderDetails?.tradeOption?.leverage || props.symbol.leverage) || "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Timer</TableCell>
                  <TableCell>{(orderDetails?.tradeOption?.timedSell || props.symbol.timedSell) / 1000}s</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{pt: 4}}><strong>Targets</strong></TableCell>
                </TableRow>
                {
                    selectedBots.map(b => {
                        return (
                            <TableRow key={`${b.ip}-${b.botName}`}>
                                <TableCell>
                                    <Typography variant="body2">{b.botName}</Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{fontSize: "0.8em"}}>{b.ip}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Checkbox checked={b.checked} sx={{m:0, p:0}} onChange={() => { let tempArray = [...selectedBots.map(bot => ({ botName: bot.botName, ip: bot.ip, checked: (bot.botName !== b.botName || bot.ip !== b.ip) ? bot.checked : !b.checked }))]; console.log('tempArray', tempArray); setSelectedBots(tempArray);}} />
                                </TableCell>
                            </TableRow>
                        )
                    })
                }
                
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant="outlined"
            sx={{ mb: 0 }}
            onClick={rejectOrder}
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                rejectOrder();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              mb: 0,
              background:
                orderDetails?.side === "Short" ? red[500] : green[500],
            }}
            onClick={submitOrder}
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                submitOrder();
              }
            }}
            autoFocus
            disabled={botsConnected === 0}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Paper
        elevation={2}
        sx={{
          m: 1,
          mb: 0,
          background: showConfirmation
            ? grey[theme.palette.mode === "dark" ? 800 : 300]
            : undefined,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              pb: 0,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "top" }}>
                <Box>
                    <Box sx={{ display: "flex", alignItems: "top" }}>
                    <Typography variant="h5" component="span" sx={{ ml: 0 }}>
                        {props.symbol.symbol}
                    </Typography>
                    <SymbolPriceAvailability symbol={props.symbol.symbol} />
                    </Box>
                    <Hidden lgDown>
                        <SymbolMarketCap symbol={props.symbol.symbol} />
                    </Hidden>
                </Box>
              <SymbolPriceHeading
                symbol={props.symbol.symbol}
                currentPrice={23456.789}
                lastMinMovement={0.0154}
                lastHourMovement={-0.0253}
                lastDayMovement={-0.0341}
              />
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center' }}>
                {/* 
              <Hidden smDown>
                <Tooltip title="Full screen">
                  <IconButton
                    onClick={() =>
                      dispatch(toggleFullscreen(props.symbol.symbol))
                    }
                  >
                    {props.symbol.fullscreen ? (
                      <FullscreenExitIcon />
                    ) : (
                      <FullscreenIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Hidden>
              */}

              <Tooltip title="TEMP PULL DATA">
                <IconButton
                  onClick={() => {
                    // Pop up the dialog
                    setExchangeData("");
                    setShowExchangeData(true);
                  }}
                >
                  <CloudDownloadIcon
                    sx={{ color: amber[500] }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title={props.symbol.pinned ? "Unpin" : "Pin to top"}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                
                        {
                            props.symbol.addedTimestamp && (date - props.symbol.addedTimestamp) <= 10000
                            ? <CircularProgress
                                variant="determinate"
                                size={48}
                                sx={{
                                    position: 'absolute',
                                    top: -5,
                                    left: -4,
                                    zIndex: 1,
                                }} 
                                value={(date - props.symbol.addedTimestamp) / 100.0} 
                            />
                            : null
                        }
                        
                        <IconButton
                            sx={{ zIndex: 99 }}
                            onClick={() => dispatch(togglePinning(props.symbol.symbol))}
                        >
                            {props.symbol.pinned ? <LockIcon /> : <PushPinIcon />}
                        </IconButton>

                    </Box>
                </Box>
              </Tooltip>
              <Tooltip title="Hide symbol">
                <IconButton
                    sx={{ zIndex: 99 }}
                  onClick={() => dispatch(removeSymbol(props.symbol.symbol))}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>

          <Hidden mdUp>
          <Grid
            item
            xs={12}
            md={12}
            lg={liveTradeSymbols.includes(props.symbol.symbol) ? 12 : 6}
            sx={{
              display: "block",
              alignItems: "center",
              justifyContent: "space-evenly",
              p: 2,
              pt: 0,
              pb: 0,
              height: "100%",
            }}
          >
            <SymbolMarketCap symbol={props.symbol.symbol} />
                    
            </Grid>
            </Hidden>
          <Grid
            item
            xs={12}
            md={12}
            lg={liveTradeSymbols.includes(props.symbol.symbol) ? 12 : 6}
            sx={{
              display: "block",
              alignItems: "center",
              justifyContent: "space-evenly",
              p: 2,
              pt: 0,
              pb: 0,
              height: "100%",
            }}
          >
            {/*</Grid>
                  
                  <Grid item xs={12} md={6} xl={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", mt: 2, p: 2, pt: 0, pb: 0, minHeight: props.isSmallScreen ? "15vh" : "25vh" }}>
                    */}
            <SymbolPriceTradingViewChart symbol={props.symbol.symbol} />
          </Grid>
          {liveTradeSymbols.includes(props.symbol.symbol) ? null : (
            <>
              <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                <Grid container>
                    {/* 
                  <Grid item xs={12} lg={12}>
                    <Collapse
                      in={props.isSmallScreen ? showTradeStrategy : true}
                      collapsedSize={40}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textAlign: "center" }}
                      >
                        <Hidden mdUp>
                          <IconButton
                            sx={{ m: 0, p: 0, mr: 2 }}
                            onClick={() => {
                              setShowTradeStrategy(!showTradeStrategy);
                            }}
                          >
                            {showTradeStrategy ? (
                              <KeyboardArrowUpIcon fontSize="small" />
                            ) : (
                              <KeyboardArrowDownIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Hidden>
                        Trade strategy
                      </Typography>
                      <Divider sx={{ m: 1 }} />
                      <Grid container>
                        <Grid
                          item
                          xs={6}
                          lg={4}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            textAlign: "center",
                            p: 1,
                            pt: 0,
                          }}
                        >
                          <TradePropertySelector
                            symbol={props.symbol.symbol}
                            label="Buy risk %"
                            options={initPercentOptions}
                            selectedValue={props.symbol.initialRisk}
                            orderPropertyName="initialRisk"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          lg={4}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            textAlign: "center",
                            p: 1,
                            pt: 0,
                          }}
                        >
                          <TradePropertySelector
                            symbol={props.symbol.symbol}
                            label="Leverage"
                            options={leverageOptions}
                            selectedValue={props.symbol.leverage}
                            orderPropertyName="leverage"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          lg={4}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            textAlign: "center",
                            p: 1,
                            pt: 0,
                          }}
                        >
                          <TradePropertySelector
                            symbol={props.symbol.symbol}
                            label="Stop loss %"
                            options={stopLossPercentOptions}
                            selectedValue={props.symbol.stopLoss}
                            orderPropertyName="stopLoss"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          lg={4}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            textAlign: "center",
                            p: 1,
                            pt: 0,
                          }}
                        >
                          <TradePropertySelector
                            symbol={props.symbol.symbol}
                            label="Trail SL %"
                            options={stopLossPercentOptions}
                            selectedValue={props.symbol.trailingStopLoss}
                            orderPropertyName="trailingStopLoss"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          lg={4}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            textAlign: "center",
                            p: 1,
                            pt: 0,
                          }}
                        >
                          <TradePropertySelector
                            symbol={props.symbol.symbol}
                            label="Take profit %"
                            options={takeProfitPercentOptions}
                            selectedValue={props.symbol.takeProfit}
                            orderPropertyName="takeProfit"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          lg={4}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            textAlign: "center",
                            p: 1,
                            pt: 0,
                          }}
                        >
                          <TradePropertySelector
                            symbol={props.symbol.symbol}
                            label="Timer"
                            options={timerOptions}
                            selectedValue={props.symbol.timedSell}
                            orderPropertyName="timedSell"
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  */}
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    xl={12}
                    sx={{ mt: { xs: 2, lg: 0, xl: 2 } }}
                  >
          
                    <Grid container>
                      
                        {tradeButtons.map((b, i) => {

                            if (b.visible === false) {
                                return null;
                            }

                            return (
                                <Grid
                                key={b.label}
                        item
                        xs={12}
                        sx={{
                          mt: props.isSmallScreen || i === 0 ? 0 : 3,
                          mr: 1,
                          ml: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          alignContent: "center",
                          border: `1px solid ${grey[500]}`
                        }}
                      >
                                <Box>
                                    <Typography variant="body2" color="text.secondary" sx={{textTransform: "uppercase", textAlign: "left", width: 60, ml: {xs: 1, md: 1}}}>
                                        {b.label}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{fontSize: '0.7em', textTransform: "uppercase", textAlign: "left", width: 90, ml: {xs: 1, md: 1}}}>
                                        {percentFormat.format(b.initPercent)} | {percentFormat.format(b.takeProfit)}
                                    </Typography>
                                </Box>
                                    <Button
                                        variant="contained"
                                        sx={{ zIndex: 99, background: green[500], m: {xs: 1, md: 2}, pt: 1, pb: 1, pl: 0, pr: 0, "&:hover": { background: green[700] }, width: {xs: 110, md: 130, lg: 170}, maxWidth: 200 }}
                                        onClick={() => {
                                            requestOrder("Long", b);
                                        }}
                                        disabled={tradingDisabled}
                                    >
                                        {b.value === null || b.value === 0 || !b.value ? "Full" : (b.value < 1
                                        ? b.value.toLocaleString(undefined, {
                                            style: "percent",
                                            minimumFractionDigits: 0,
                                            })
                                        : b.value.toLocaleString(undefined, {
                                            style: "currency",
                                            currency: "USD",
                                            currencyDisplay: "narrowSymbol",
                                            maximumFractionDigits: 0,
                                            }))}{/*" "*/}
                                        {/*props.index === 0 && !props.isSmallScreen && b.shortcutKeys[0]
                                        ? `[${b.shortcutKeys[0]}]`
                                        : ""*/}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ zIndex: 99, background: red[500], m: {xs: 1, md: 2}, pt: 1, pb: 1, pl: 0, pr: 0, "&:hover": { background: red[700] }, width: {xs: 110, md: 130, lg: 170}, maxWidth: 200 }}
                                        onClick={() => {
                                            requestOrder("Short", b);
                                        }}
                                        disabled={tradingDisabled}
                                    >
                                        {b.value === null || b.value === 0 || !b.value ? "Full" : (b.value < 1
                                        ? b.value.toLocaleString(undefined, {
                                            style: "percent",
                                            minimumFractionDigits: 0,
                                            })
                                        : b.value.toLocaleString(undefined, {
                                            style: "currency",
                                            currency: "USD",
                                            currencyDisplay: "narrowSymbol",
                                            maximumFractionDigits: 0,
                                            }))}{/*" "*/}
                                        {/*props.index === 0 && !props.isSmallScreen && b.shortcutKeys[1]
                                        ? `[${b.shortcutKeys[1]}]`
                                        : ""*/}
                                    </Button>
                                
                                </Grid>
                            )
                        })}
                        
                      

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

                        {
                            liveTradeSymbols.includes(props.symbol.symbol) 
                                ? null 
                                :
                                
                                    
                                    
                                    <Grid item xs={12} sx={{ textAlign: "left", ml: 2, mr: 2 }}>
                                    <Divider sx={{mt: 2, mb: 0}} />
                                    {
                                        props.symbol.news === undefined
                                            ? <><Typography variant="body2" sx={{mt: 2, textAlign: "center"}}>Loading news...</Typography><Divider sx={{mt: 2, mb: 0}} /></>
                                            : (props.symbol.news || []).length === 0
                                                ? <><Typography variant="body2" sx={{mt: 2, textAlign: "center"}}>No news found</Typography><Divider sx={{mt: 2, mb: 0}} /></>
                                                : <NewsTable items={(props.symbol.news || [])} />
                                    }
                                    
                                </Grid>
                                
                        }

          <Grid item xs={12} sx={{ textAlign: "left", ml: 2, mr: 2 }}>
            <SymbolTrades symbol={props.symbol.symbol} isTinyScreen={false} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default SymbolContainer;
