import { Middleware } from "redux";
import {
  requestConnect,
  updatePrices,
  removePrice,
  updateQuotes,
  AvailableQuotes,
  addCurrentPrice
} from "../reducers/priceReducer";
import {
    addSymbol,
    removeSymbol,
    SymbolConfig
  } from "../reducers/symbolReducer";
import { Price, addPrice } from "../reducers/priceReducer";
import { updateTerminalTab, selectMaxCoins } from "../reducers/terminalReducer";

let socket:WebSocket|null = null;

let defaultSymbols:string[] = [];

let reconnecting = false;
let connected = false;

// Use this for testing if the updates are too much and slowing things down
const disabled = false;

const priceMiddleware: Middleware = (store) => (next) => (action) => {

    if (!requestConnect.match(action) && !addSymbol.match(action) && !removeSymbol.match(action) && !addPrice.match(action) && !addCurrentPrice.match(action)) {
        return next(action);
    }

    let maxSymbols = store.getState().terminal.maxCoins;

    //let socket: WebSocket | null = null;

  

  function connect() {

    socket = new WebSocket("wss://prices.kryptrader.com");
  
    console.log('Connecting to price WS server');

    socket.onopen = function (event) {
        console.log('Connection to price server is open');

        // Set connected to true after 200ms
        setTimeout(() => {
            connected = true;
        }, 200);
    }

    socket.onmessage = function (event) {

        let msg = event.data;

        try {
            let parsedData = JSON.parse(msg);

            if (event.data.includes("quotes")) {
                store.dispatch(updateQuotes(parsedData as AvailableQuotes[]));
            }
            else {
                //console.log('Received price data from server', parsedData);
                store.dispatch(updatePrices(parsedData as Price[]));
            }
        }
        catch (ex) {
            console.error(ex);
        }
    };  

    // Add default symbols
    setTimeout(() => {

        defaultSymbols = store.getState().prices.defaultSymbols.split(",");
        defaultSymbols.map(s => { store.dispatch(addPrice(s)); });
        store.getState().prices.watchedSymbols.map(((p:string) => {
            if (connected && !disabled) {
                socket?.send(`{"subscribe": "${p.trim()}"}`);
            }
        }))
        
    }, 3000);
    
    socket.onclose = function (event) {
        console.log('Price server has been disconnected');
        connected = false;
      console.log(event);
      if (!reconnecting) {
        reconnecting = true;
        socket = null;
        setTimeout(() => connect(), 5000);
      }
    };
  
    socket.onerror = function (event) {
        console.log('Price server has been disconnected');
        connected = false;
      console.log(event);
      if (!reconnecting) {
        reconnecting = true;
        socket = null;
        setTimeout(() => connect(), 5000);
      }
    };
  }

  // Connect to the news service
  // This includes all message handlers & reconnection logic
  if (requestConnect.match(action)) {
    console.log('Requesting connection to price server');
    connect();
  }

  if (addSymbol.match(action)) {

    // Check if it's a CSV
    let symbols = [action.payload.symbol];
    if (action.payload.symbol.includes(",")) {
        symbols = action.payload.symbol.split(",")
    }

    for (let i = 0; i < symbols.length; i++) {
        if (symbols[i] in store.getState().prices === false) {
            if (connected && !disabled) {
                socket?.send(`{"subscribe": "${symbols[i]}"}`);
                store.dispatch(addPrice(symbols[i]));
            }
            else {
                console.log('Did not subscribe to ' + symbols[i] + ' because we are not connected to the price server');
            }
        }
        else {
            console.log(`[addSymbol] Not subscribing to the price feed for ${symbols[i]} because it's already in the list of watched symbols.`);
        }
    }
    
    // Make sure we're on the Execute tab
    if (action.payload.pinned) {
        store.dispatch(updateTerminalTab(0));
    }

    // Remove others from the list
    let priceArray = store.getState().prices.watchedSymbols.filter((p:string) => defaultSymbols.includes(p) === false);
    let pinnedSymbol = store.getState().symbols.symbols.find((s:SymbolConfig) => s.pinned === true)?.symbol;

    if (priceArray.length > maxSymbols) {
        for (let i = 0; i < priceArray.length - maxSymbols; i++) {
            if (priceArray[i] !== pinnedSymbol) {
                store.dispatch(removeSymbol(priceArray[i]));
            }
        }
    }
  }
  
  if (addPrice.match(action)) {
    if (action.payload in store.getState().prices === false) {
        if (connected && !disabled) {

            // Unsubscribe and resubscribe to the price feed if we only have 'current price'
            if (action.payload in store.getState().prices.currentPrices.map((p:any) => p.base) === false) {
                socket?.send(`{"unsubscribe": "${action.payload}"}`);
            }

            socket?.send(`{"subscribe": "${action.payload}"}`);
        }
        //store.dispatch(addPrice(action.payload));
    }
    else {
        console.log(`[addPrice] Not subscribing to the price feed for ${action.payload} because it's already in the list of watched symbols.`);
    }
  }

  if (addCurrentPrice.match(action)) {
    if (action.payload in store.getState().prices.currentPrices.map((p:any) => p.base) === false) {
        if (connected && !disabled) {
            socket?.send(`{"subscribe": "${action.payload}"}`);
        }
        //store.dispatch(addPrice(action.payload));
    }
    else {
        console.log(`[addCurrentPrice] Not subscribing to the price feed for ${action.payload} because it's already in the list of watched symbols.`);
    }
  }

  if (removeSymbol.match(action)) {
    if (defaultSymbols.includes(action.payload) === false) {
        if (connected && !disabled) {
            console.log('Unsubscribing from price feed for ' + action.payload);
            socket?.send(`{"unsubscribe": "${action.payload}"}`);
            store.dispatch(removePrice(action.payload));
        }
    }
  }
  
  next(action);
};

export default priceMiddleware;
