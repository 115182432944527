import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { StandardiseCoinName } from "../utils/PriceUtils";

export interface ExchangeMarket {
    exchange: string,
    market: string,
}

// Define a type for the slice state
interface PriceState {
  requestNewConnection: boolean;
  isConnected: boolean;
  lastUpdated: number;
  //prices: { [symbol: string]: Price[] };
  [key: string]: AvailableQuotes[] | string | string[] | boolean | number | Price[] | CurrentPrice[] | ExchangeMarket[]; // | { [symbol: string]: Price[] }; // Delete the last one when we've refactored
  availableQuotes: AvailableQuotes[];
  exchanges: string[];
  exchangeMarkets: ExchangeMarket[];
  defaultSymbols: string;
  watchedSymbols: string[];
  currentPrices: CurrentPrice[];
  //history: { [symbol: string]: HistoricalPrice[] };
  //movers: Mover[];
  //moverCache: Mover[];
}

export interface CurrentPrice {
    base: string,
    quote: string,
    price: number,
}

export interface Price {
  exchange: string;
  market: string;
  base: string;
  quote: string;
  price: number;
  history: HistoricalPrice[],
  currentKline: HistoricalPrice | null,
  bid: number;
  ask: number;
}

export interface AvailableQuotes {
    exchange: string,
    market: string,
    base: string,
    exchangeBase: string,
    quotes: string[],
}

const movement5sec = 0.025;
const movement15sec = 0.05;
const movement30sec = 0.07;

interface MoverCache extends Mover {}

interface Mover {
  base: string;
  price: number;
  price5sAgo: number;
  price15sAgo: number;
  price30sAgo: number;
  time: number | undefined; // Used for the cache object
}

interface HistoricalPrice {
  timestamp: number;
  price: number;
  open?: number;
    high?: number;
    low?: number;
    close?: number;
}

// Define the initial state using that type
const initialState: PriceState = {
  requestNewConnection: false,
  isConnected: false,
  lastUpdated: new Date().valueOf(),
  //prices: {},
  availableQuotes: [],
  exchanges: ["Binance", "GateIo", "KuCoin", "MEXC", "ByBit"],
  exchangeMarkets: [ 
    { exchange: "Binance", market: "Spot" }, // priority 1
    { exchange: "ByBit", market: "Futures" },  // priority 2
    { exchange: "GateIo", market: "Spot" },  // priority 3
    { exchange: "MEXC", market: "Spot" },  // priority 4
    { exchange: "Binance", market: "Futures" }, 
    //{ exchange: "BingX", market: "Spot" }, 
    { exchange: "Bitget", market: "Spot" }, 
    { exchange: "Bitget", market: "Futures" },
    { exchange: "ByBit", market: "Spot" }, 
    { exchange: "Coinbase", market: "Spot" }, 
    { exchange: "GateIo", market: "Futures" },
    { exchange: "KuCoin", market: "Spot" }, 
    { exchange: "OKX", market: "Spot" }, 
    //{ exchange: "WooX", market: "Spot" }, 
    //{ exchange: "WooX", market: "Futures" }
],
  defaultSymbols: "BTC,ETH",
  watchedSymbols: ["BTC", "ETH"],
  currentPrices: [],
  //history: {},
  //movers: [],
  //moverCache: [],
};

export const priceSlice = createSlice({
  name: "prices",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    requestConnect: (state) => {
      state.requestNewConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.requestNewConnection = false;
    },
    addPrice: (state, action: PayloadAction<string>) => { 

        //console.log(`addPrice: ${action.payload}`);

        // Only update if it's not already tracked
        if (!state[StandardiseCoinName(action.payload, "", "")]) {
            state[StandardiseCoinName(action.payload, "", "")] = [] as Price[];
        }
        
        if (state.watchedSymbols.indexOf(action.payload) === -1) {
            state.watchedSymbols = [...state.watchedSymbols.filter(s => StandardiseCoinName(s, "", "") !== StandardiseCoinName(action.payload, "", "")), StandardiseCoinName(action.payload, "", "")].filter((s, i, a) => a.indexOf(s) === i);
        }
    },
    addCurrentPrice: (state, action: PayloadAction<string>) => { 
        //console.log(`addCurrentPrice: ${action.payload}`);
        state.currentPrices = [...state.currentPrices.filter(s => StandardiseCoinName(s.base, "", "") !== StandardiseCoinName(action.payload, "", "")), { "base": StandardiseCoinName(action.payload, "", ""), "quote": "USDT", price: 0}].filter((s, i, a) => a.indexOf(s) === i);
    },
    removePrice: (state, action: PayloadAction<string>) => {
        //console.log(`removePrice: ${action.payload}`);
        state.watchedSymbols = state.watchedSymbols.filter(s => s !== action.payload).filter((s, i, a) => a.indexOf(s) === i);
        delete state[action.payload];
     },
     removeCurrentPrice: (state, action: PayloadAction<string>) => {
        //console.log(`removeCurrentPrice: ${action.payload}`);
        state.currentPrices = state.currentPrices.filter(s => StandardiseCoinName(s.base, "", "") !== StandardiseCoinName(action.payload, "", "")).filter((s, i, a) => a.indexOf(s) === i);
     },
    updateQuotes: (state, action: PayloadAction<AvailableQuotes[]>) => {
        state.availableQuotes = action.payload;
      },
    updatePrices: (state, action: PayloadAction<Price[]>) => {
      let currentTime = new Date().valueOf();
      state.lastUpdated = currentTime;

      // Prices
      for (let i = 0; i < action.payload.length; i++) {
        if (state[StandardiseCoinName(action.payload[i].base, "", "")]) {
            let exchangeQuotePrice = (state[StandardiseCoinName(action.payload[i].base, "", "")] as Price[])?.find(p => p.exchange === action.payload[i].exchange && p.quote === action.payload[i].quote);
            if (exchangeQuotePrice) {

                if (action.payload[i].history) {

                    // Log this action
                    // console.log(`${new Date().valueOf()} updatePricesWithHistory: ${action.payload[i].base} ${action.payload[i].exchange} ${action.payload[i].quote}`);

                    exchangeQuotePrice = {...action.payload[i]};
                }
                else {

                    // Log this action
                    // console.log(`${new Date().valueOf()} updatePricesWithoutHistory: ${action.payload[i].base} ${action.payload[i].exchange} ${action.payload[i].quote}`);

                    exchangeQuotePrice.price = action.payload[i].price;

                    if (!exchangeQuotePrice.history) {
                        exchangeQuotePrice.history = [];
                    }

                    if (action.payload[i].currentKline !== null) {
                        let index = exchangeQuotePrice.history.findIndex(q => q.timestamp === action.payload[i].currentKline?.timestamp);
                        if (index === -1) {
                            // Add to the price history
                            exchangeQuotePrice.history.push(action.payload[i].currentKline as HistoricalPrice);
                        }
                        else {
                            // Replace the price history for this item
                            if (JSON.stringify(action.payload[i].currentKline) !== JSON.stringify(exchangeQuotePrice.history[index])) {
                                exchangeQuotePrice.history.splice(index, 1, action.payload[i].currentKline as HistoricalPrice);
                                //exchangeQuotePrice.history.length = Math.min(exchangeQuotePrice.history.length, 6 * 60 * 1); // Keep 1 hour max (may need to increase this somehow)
                            }
                        }

                        // Set the max length of the array (3 hours)
                        if (exchangeQuotePrice.history.length > 6 * 60 * 3) {
                            exchangeQuotePrice.history.splice(0, 1);//.length = 6 * 60 * 3;
                        }
                    }
                }
            }
            else {
                // On a new subscription, wait for the record with history (in case they're out of sync)
                if (action.payload[i].history) {

                    // Log this action
                    // console.log(`${new Date().valueOf()} updatePrices: ${action.payload[i].base} ${action.payload[i].exchange} ${action.payload[i].quote}`);

                    // Uadd the payload to the state
                    /*
                    if (!state[action.payload[i].base]) {
                        state[action.payload[i].base] = [];
                    }
                    else {
                        // Remove the record without history
                        state[action.payload[i].base] = (state[action.payload[i].base] as Price[]).filter(p => p.exchange !== action.payload[i].exchange || p.quote !== action.payload[i].quote);
                    }
*/
                    state[StandardiseCoinName(action.payload[i].base, "", "")] = [...(state[StandardiseCoinName(action.payload[i].base, "", "")] as Price[]).filter(p => p.exchange !== action.payload[i].exchange || p.quote !== action.payload[i].quote), action.payload[i]];

                    //state[action.payload[i].base] = action.payload[i] as Price[];
                }
            }

            //state.prices[action.payload[i].base].price  = [...state.prices[action.payload[i].base]?.filter(p => p.exchange !== action.payload[i].exchange || p.quote !== action.payload[i].quote), action.payload[i]];
        }

        if (state.currentPrices.find(p => StandardiseCoinName(p.base, "", "") === StandardiseCoinName(action.payload[i].base, "", "") && action.payload[i].quote === "USDT")) {
            // Update the current price
            let currentPrice = state.currentPrices.find(p => StandardiseCoinName(p.base, "", "") === StandardiseCoinName(action.payload[i].base, "", ""));
            if (currentPrice) {
                currentPrice.price = action.payload[i].price;
                state.currentPrices = [...state.currentPrices.filter(p => StandardiseCoinName(p.base, "", "") !== StandardiseCoinName(action.payload[i].base, "", "")), currentPrice];
            }
        }
      }
    },

    updateDefaultSymbols: (state, action: PayloadAction<string>) => {
      state.defaultSymbols = action.payload;
    },
    
  },
});

export const { requestConnect, connectionEstablished, updatePrices, updateQuotes, addPrice, removePrice, addCurrentPrice, removeCurrentPrice, updateDefaultSymbols } =
  priceSlice.actions;

// Other code such as selectors can use the imported `RootState` type
//export const prices = (state: RootState) => state.prices.prices;
export const selectWatchedSymbols = (state: RootState) => state.prices.watchedSymbols;
export const selectAvailableQuotes = (state: RootState) => state.prices.availableQuotes;
export const selectExchanges = (state: RootState) => state.prices.exchanges;
export const selectExchangeMarkets = (state: RootState) => state.prices.exchangeMarkets;
export const selectDefaultSymbols = (state: RootState) => state.prices.defaultSymbols;
export const selectLastUpdated = (state: RootState) => state.prices.lastUpdated;
export const selectCurrentPrices = (state: RootState) => state.prices.currentPrices;
//export const history = (state: RootState) => state.prices.history;
//export const movers = (state: RootState) => state.prices.movers;

// Get all prices for a specific symbol
export const selectPrices = (state: RootState, key: string) => {
    return state.prices[key] as Price[];
};

export default priceSlice.reducer;
