import { AvailableQuotes, ExchangeMarket, Price } from "../reducers/priceReducer";

export function GetLastPeriodPrice(prices:Price[], exchange:string, duration:number, quote:string) {

    let binancePrice = prices?.find(s => s.exchange === exchange && s.quote === quote);

    let lastPeriodPrice = binancePrice?.history?.find(
      (p) => p.timestamp < (new Date().valueOf() / 1000) - duration && p.timestamp > (new Date().valueOf() / 1000) - (duration+30)
    );
  
    let lastPeriodMovement = 0;
    if (lastPeriodPrice && binancePrice) {
        lastPeriodMovement = binancePrice.price / lastPeriodPrice.price - 1.0;
    }
  
    return lastPeriodMovement;
  
}

export function GetPrimaryQuote(symbol: string, exchangeMarkets:ExchangeMarket[], availableQuotes: AvailableQuotes[]) {

    let exchange: string = "";
    let market: string = "";
    let quote: string = "";

// Go through the exchange list in order and find a match
for (let i = 0; i < exchangeMarkets.length; i++) {
    let foundQuote = availableQuotes.find(q => StandardiseCoinName(q.base, q.exchange, q.market) === symbol && q.exchange.toLowerCase() === exchangeMarkets[i].exchange.toLowerCase() && (q.market === exchangeMarkets[i].market || q.market === ""));
    if (foundQuote !== undefined) {
        exchange = foundQuote.exchange;
        market = foundQuote.market;
        if (foundQuote.quotes.includes("USDT")) {
            quote = "USDT";
        }
        else if (foundQuote.quotes.includes("BUSD")) {
            quote = "BUSD";
        }
        else if (foundQuote.quotes.includes("BTC")) {
            quote = "BTC";
        }
        i = exchangeMarkets.length;
    }
}

//console.log(`${exchange}: ${symbol}/${quote}`)

return [exchange, market, quote];

}

export function StandardiseCoinName(coin: string, exchange: string, market: string) {
    return coin.replace("1M", "")
            .replace("10000000", "")
            .replace("1000000", "")
            .replace("100000", "")
            .replace("10000", "")
            .replace("1000", "")
            .replace("100", "")
            .replace("10", "")
            .replace("2L", "")
            .replace("3L", "")
            .replace("4L", "")
            .replace("5L", "")
            .replace("2S", "")
            .replace("3S", "")
            .replace("4S", "")
            .replace("5S", "");
}