import {
  Box,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { status } from "../../reducers/terminalReducer";

interface ComponentProps {
  symbol: string;
  isTinyScreen: boolean;
}

const usdtLocaleProfit = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

function SymbolProfit(props: ComponentProps) {
  
const botStatus = useSelector(status);

    let trades = botStatus.flatMap(b => b.trades)
        .filter((t) =>
            t.baseCurrency.startsWith(props.symbol)
        )
        .sort((a, b) =>
            a.baseCurrency < b.baseCurrency ? -1 : 1
    );

    return (
        trades.length === 0
            ? <Box sx={{ pt: 2, pb: 2 }}></Box>
            : <Box sx={{ pt: 1, display: 'block', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body2">
                    <strong>Trigger: </strong> {trades[0].trigger?.replace("|", " | ")}
                </Typography>
                {!props.isTinyScreen
                ?
                    <Typography variant="body2">
                        <strong>Strategy: </strong> {trades[0].tradeSettings?.name?.replace("|", " | ")}
                    </Typography>
                : null}
            </Box>
    );
    }

export default SymbolProfit;
