import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { isConnected, connectedCount, requestConnect, disconnect, BotConnection, startConnecting, getPersonalisationSettings, getPersonalisationSettingsAlternate } from "./reducers/terminalReducer";
import { Badge, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { selectAccessToken, selectBasicLogin, selectIsLoggedIn } from "./reducers/authorizationSlice";
import { Authorization } from "./auth/Authorization";
import { LoadingButton } from "@mui/lab";
import WifiIcon from "@mui/icons-material/Wifi";
import { requestConnect as requestNewConnectionPrices  } from "./reducers/priceReducer";
import { requestConnect as requestNewConnectionNews  } from "./reducers/newsReducer";

export default function ConnectionStatus() {
  
  const connected = useSelector(isConnected, shallowEqual);
  const botCount = useSelector(connectedCount, shallowEqual);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const accessToken = useSelector(selectAccessToken); 
  const basicLogin = useSelector(selectBasicLogin); 

  const [anchorEl, setAnchorEl] = React.useState<Element|null>(null);

  const [open, setOpen] = React.useState<boolean>(!isLoggedIn);
  const [connecting, setConnecting] = React.useState(false);

  //const showNewConnection = useSelector(requestNewConnection);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (connecting) {
      window.setTimeout(() => {
        setOpen(false);
      }, 1500);
    }
  }, [connecting]);

  const [botConnection, setBotConnection] = React.useState<BotConnection>({
    targetIp: "",
    authCode: "",
    disabled: true,
  });

  const isBotConnected = useSelector(isConnected);

  const connect = () => {
    setConnecting(true);

    console.log('Connected to bot', botConnection)

    dispatch(startConnecting(botConnection));

    // If this is the first bot to connect, set up the news & price connections now
    if (isBotConnected === false) {
        dispatch(requestNewConnectionPrices());
        dispatch(requestNewConnectionNews());
    }
  }

  React.useEffect(() => {
    setConnecting(true);
  }, []);
  

  React.useEffect(() => {
    dispatch(getPersonalisationSettings());
  }, [accessToken]);

  React.useEffect(() => {
    // Can be a different function in the future if needed, but for now the same logic will handle Discord Auth tokens & direct username/password/code
    dispatch(getPersonalisationSettings());
  }, [basicLogin]);

  React.useEffect(() => {
    setConnecting(false);
    setBotConnection({
        targetIp: "",
        authCode: "",
        disabled: true,
        });
  }, [open, botCount])

  React.useEffect(() => {
    if (isLoggedIn && botCount === 0) {
        connect();
    }
  }, [isLoggedIn])
  
  // TODO: Read terminal WS state

  return (
    <React.Fragment>
      {connected ? (
        <Tooltip
          title={`Connected and receiving from ${botCount} trading bots`}
        >
          
            <IconButton
              onClick={(e:any) => { setAnchorEl(e.currentTarget);
                //dispatch(disconnect());
              }}
            >
                <Badge badgeContent={botCount} color="primary">
              <CloudUploadIcon
                color={botCount === 0 ? "error" : "inherit"}
              />
              </Badge>
            </IconButton>
          
        </Tooltip>
      ) : (
        <Tooltip title="Not connected to trading bot service">
          <IconButton
            onClick={() => {
              dispatch(requestConnect());
            }}
          >
            <CloudOffIcon />
          </IconButton>
        </Tooltip>
      )}

{botCount === 0 || open
?

      <Dialog
        maxWidth="md"
        sx={{ p: 2 }}
        open={!isLoggedIn || open}
        onClose={() => {
          setOpen(false);
        }}
        BackdropProps={{ style: { backgroundColor: 'black' } }}
      >

{
    !isLoggedIn
    ? <Authorization />
    : 
        <form action="javascript:void(0);">
          <DialogTitle>Connect to your bot(s)</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} padding={2}>
                <TextField
                  sx={{ mt: 3 }}
                  name="target"
                  value={botConnection.targetIp}
                  label="Trading bot IP"
                  fullWidth
                  disabled={connecting}
                  onChange={(e:any) => {
                    setBotConnection({
                      ...botConnection,
                      targetIp: e.currentTarget.value,
                      disabled: false,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} padding={2}>
                <TextField
                  name="authKey"
                  value={botConnection.authCode}
                  label="Authorization token"
                  type="password"
                  disabled={connecting}
                  fullWidth
                  onChange={(e:any) => {
                    setBotConnection({
                      ...botConnection,
                      authCode: e.currentTarget.value,
                      disabled: false,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <LoadingButton
              variant="contained"
              onClick={() => {
                connect();
              }}
              loading={connecting}
              loadingPosition="start"
              startIcon={<WifiIcon />}
              type="submit"
              onKeyPress={(e:any) => {
                if (e.key === "Enter" || e.key === "Space") {
                  connect();
                }
              }}
            >
              Connect
            </LoadingButton>
          </DialogActions>
        </form>
    }
      </Dialog>
: null}
      <Menu
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => { setAnchorEl(null); }}

      >
        <MenuItem onClick={() => {setOpen(true);dispatch(requestConnect());}}>Add new connection</MenuItem>
        <MenuItem onClick={() => {dispatch(disconnect());}}>Disconnect all</MenuItem>
      </Menu>
      
    </React.Fragment>
  );
}