import React from "react";
import { Box, Hidden, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { shallowEqual, useSelector } from "react-redux";
import { Price, selectExchangeMarkets, selectPrices } from "../../reducers/priceReducer";
import { selectAvailableQuotes, selectExchanges } from "../../reducers/priceReducer";
import { MovementInfo } from "./SymbolPrice";
import { GetPrimaryQuote } from "../../utils/PriceUtils";
import { RootState } from "../../store";


interface ComponentProps {
  symbol: string;
  currentPrice: number;
  lastMinMovement: number;
  lastHourMovement: number;
  lastDayMovement: number;
}

// USDT formatter
// Todo - different precision models for diff currencies
const usdtLocale = Intl.NumberFormat("en-US", {
  style: "decimal",
  currency: "USD",
  //maximumSignificantDigits: 7,
  minimumFractionDigits: 2,
  maximumFractionDigits: 7,
});

function SymbolPriceHeading(props: ComponentProps) {
  
  const prices:Price[] = useSelector(
    (state) => selectPrices(state as RootState, props.symbol),
    shallowEqual
  );

  const availableQuotes = useSelector(selectAvailableQuotes);
  const exchanges = useSelector(selectExchanges);
  const exchangeMarkets = useSelector(selectExchangeMarkets);

  let arr = GetPrimaryQuote(props.symbol, exchangeMarkets, availableQuotes);
  let exchange = arr[0];
  let market = arr[1];
  let quote = arr[2];

  let exchangePrice = prices?.find(s => s.exchange === exchange && s.market == market && s.quote === quote);

  const [showAvailability, setShowAvailability] = React.useState<boolean>(false);

  
let lastMinPrice = exchangePrice?.history?.find(
  (p) => p.timestamp < new Date().valueOf() / 1000 - 60 && p.timestamp > new Date().valueOf() / 1000 - 82
);

let lastMinMovement = 0;
if (lastMinPrice && exchangePrice) {
  lastMinMovement = exchangePrice.price / lastMinPrice.price - 1.0;
}

let last2MinPrice = exchangePrice?.history?.find(
  (p) => p.timestamp < new Date().valueOf() / 1000 - 2 * 60 && p.timestamp > new Date().valueOf() / 1000 - 142
);

let last2MinMovement = 0;
if (last2MinPrice && exchangePrice) {
  last2MinMovement = exchangePrice.price / last2MinPrice.price - 1.0;
}

let last5MinPrice = exchangePrice?.history?.find(
    (p) => p.timestamp < new Date().valueOf() / 1000 - 5 * 60 && p.timestamp > new Date().valueOf() / 1000 - 322
  );
  
  let last5MinMovement = 0;
  if (last5MinPrice && exchangePrice) {
    last5MinMovement = exchangePrice.price / last5MinPrice.price - 1.0;
  }

  return (
    <>
    <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>

      <Typography
        variant="h5"
        component="span"
        sx={{ ml: 1, fontWeight: 500 }}
        color={
          props.lastMinMovement < 0
            ? red[700]
            : props.lastMinMovement > 0
            ? green[600]
            : undefined
        }
      >
        {
            exchangePrice?.quote === "USDT" || exchangePrice?.quote === "BUSD"
            ? usdtLocale.format(exchangePrice?.price || 0)
            : (exchangePrice?.price || 0).toFixed(10)
        }
        
      </Typography>

      </Box>
      <Hidden lgDown>
      <Box sx={{display: 'flex', justifyContent: 'space-between' }}>
          
            <MovementInfo marginLeft={4} movement={lastMinMovement} label="1 min" chartDurationChange={() => {  }} />
          
            <MovementInfo marginLeft={4} movement={last2MinMovement} label="2 min" chartDurationChange={() => { }} />
          
          <MovementInfo marginLeft={4} movement={last5MinMovement} label="5 min" chartDurationChange={() => { }} />
          </Box>
          </Hidden>
        </>

  );
}

export default SymbolPriceHeading;
