import React from "react";
import AppBar from "@mui/material/AppBar";
import { Toolbar } from '@mui/material';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormGroup, FormControl, Link as MaterialLink, Hidden, Typography, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ConnectionStatusMobile from "./ConnectionStatusMobile";
//import { useHotkeys } from "react-hotkeys-hook";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addSymbol } from "./reducers/symbolReducer";
import Help from "./Help";
import { selectDisplayName } from "./reducers/discordReducer";
import UserSettings from "./UserSettings";
import { Link } from "react-router-dom";
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { selectEnableSpeaking, selectIsMuted, setEnableSpeaking, setIsMuted } from "./reducers/authorizationSlice";
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Diagnostics from "./Diagnostics";
import DiagnosticsButton from "./DiagnosticsButton";
import { connectedCount, lastWebsocketReceived } from "./reducers/terminalReducer";
import { Check, Close, CloudDoneOutlined, CloudOffOutlined } from "@mui/icons-material";
import { green, grey } from "@mui/material/colors";

function HeaderMobile() {
//  const context = React.useContext<SymbolWatcherContextInterface | null>(SymbolWatcherContext);

  const [showDialog, setShowDialog] = React.useState(false);
  const [value, setValue] = React.useState<string>("");

  const displayName = useSelector(selectDisplayName);
  const isMuted = useSelector(selectIsMuted);
  const enableSpeaking = useSelector(selectEnableSpeaking);

  const botCount = useSelector(connectedCount, shallowEqual);
  const relayLastUpdated = useSelector(lastWebsocketReceived);
  const lastRelayUpdate = new Date().valueOf() - relayLastUpdated;

  const dispatch = useDispatch();

    // Build a state update to force a re-render at least every 1 second
    // This is a hack to ensure the Diagnostics component is updated every second
    React.useEffect(() => {
        const interval = setInterval(() => {
            dispatch({ type: "FORCE_UPDATE" });
        }, 1000);
        return () => clearInterval(interval);
    }, [dispatch]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar variant="dense">
            
            <Typography
              variant="body1"
              component="div"
              sx={{ flexGrow: 1, marginLeft: 1, textAlign: "left" }}
            >
                <Link to="/" style={{textDecoration:'none'}}>
                    <MaterialLink color="text.primary" sx={{textDecoration:'none'}}>
                        Terminal
                    </MaterialLink>
              </Link>

            </Typography>

            <Typography variant="body1" component="div">
                {
                    // Current time in 24h format hh:mm:ss
                    new Date().toUTCString().split(" ")[4]
                }
                <span style={{fontSize: '0.75em', color: grey[500]}}>Z</span>
            </Typography>

            <Typography
              variant="body1"
              component="div"
              sx={{ flexGrow: 1, marginLeft: 1, display: 'flex', textAlign: "right", justifyContent: "end", alignItems: "end" }}
            >
                {botCount}
                
                {
                    lastRelayUpdate < 5000 
                        ? <CloudDoneOutlined sx={{ml: 1}} />
                        : <CloudOffOutlined sx={{ml: 1}} />
                }
            </Typography>

{/* 
            <Help />

            <Tooltip title={enableSpeaking ? "Shut up" : "Speak to me"}>
                <IconButton
                    onClick={() => { dispatch(setEnableSpeaking(!enableSpeaking)) }}
                >
                    {
                        enableSpeaking ? <VoiceOverOffIcon /> : <RecordVoiceOverIcon />
                    }
                </IconButton>
            </Tooltip>

            <Tooltip title={isMuted ? "Unmute" : "Mute"}>
                <IconButton
                    onClick={() => { dispatch(setIsMuted(!isMuted)) }}
                >
                    {
                        isMuted ? <VolumeOffIcon /> : <VolumeMuteIcon />
                    }
                </IconButton>
            </Tooltip>

            <DiagnosticsButton />

            <UserSettings />
*/}
            <ConnectionStatusMobile />

          </Toolbar>
        </AppBar>
      </Box>
      
    </>
  );
}

export default HeaderMobile;
